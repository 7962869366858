import { Button, ModuleBase, ResponsiveImage } from '@/components';
import { isColorDark, themeFromBg } from '@/utils';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { transition } from '@/components/animations/FadeUpAnimation/transition';

const SocialMediaCarousel = ({ data }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [mouseOver, setMouseOver] = useState(false);

  useEffect(() => {
    setIsSmallScreen(window.innerWidth < 1024);

    const onResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  let timerID = 0;

  useEffect(() => {
    if (mouseOver) {
      clearTimeout(timerID);

      // eslint-disable-next-line
      timerID = setTimeout(() => {
        setCurrent((current + 1) % data.images.length);
      }, 250);
    } else {
      setTimeout(() => {
        clearTimeout(timerID);
        setCurrent(0);
      }, 200);
    }

    return () => {
      clearTimeout(timerID);
    };
  }, [current, mouseOver]);

  return (
    <ModuleBase data={data}>
      <motion.div
        variants={{ inactive: {}, active: {} }}
        initial="inactive"
        whileInView="active"
        viewport={{ once: true }}
        className="w-full h-[618px] lg:h-[900px] container grid place-items-center"
      >
        <div className="flex flex-col items-center justify-center h-full">
          {data.label && (
            <motion.p
              variants={{ inactive: { opacity: 0 }, active: { opacity: 1, transition: { ...transition, delay: 1 } } }}
              className="color-from-bg text-sm leading-4 -tracking-[0.02em] lg:mb-[182px] mt-20 lg:mt-[199px]"
            >
              {data.label}
            </motion.p>
          )}

          {data.heading && !isSmallScreen && (
            <div
              className={`flex relative w-full flex-col items-center justify-center ${!data.primaryCTA ? 'mb-auto' : ''} ${!data.label ? 'mt-auto' : ''}`}
            >
              <p
                className={`font-ivy-ora text-title-xxl text-center leading-120 -tracking-[2.4px] ${isColorDark(data.backgroundColour) ? 'text-secondary-coolsand' : 'text-secondary-hazyblue'}`}
                role="heading"
                aria-level="2"
                aria-label={data.heading}
              >
                {data.heading}
              </p>

              <motion.div
                variants={{
                  inactive: { opacity: 0 },
                  active: { opacity: 1, transition: { ...transition, delay: 1 } },
                }}
                className="absolute"
              >
                <div
                  className="opacity-30 hover:opacity-100 aspect-square w-[400px] transition-opacity duration-400 relative"
                  onMouseOver={() => setMouseOver(true)}
                  onMouseLeave={() => setMouseOver(false)}
                >
                  {data.images.map((image, index) => (
                    <div
                      key={index}
                      className={`absolute w-full h-full max-h-[308px] lg:max-h-[400px] ${index !== current ? 'opacity-0' : 'opacity-1'}`}
                      style={{ zIndex: data.images.length - index }}
                    >
                      <ResponsiveImage
                        image={image}
                        widths={{ xs: 308, sm: 308, md: 308, lg: 400, xl: 400, xxl: 400 }}
                        heights={{ xs: 308, sm: 308, md: 308, lg: 400, xl: 400, xxl: 400 }}
                        showImageCredit
                      />
                    </div>
                  ))}
                </div>
              </motion.div>
            </div>
          )}
          {isSmallScreen && (
            <div
              className={`my-12 w-[308px] aspect-square transition-opacity duration-400 relative select-none ${!data.primaryCTA ? 'mb-auto' : ''} ${!data.label ? 'mt-auto' : ''}`}
              onTouchStart={() => setMouseOver(true)}
              onTouchEnd={() => setMouseOver(false)}
            >
              {data.images.map((image, index) => (
                <div
                  key={index}
                  className={`absolute w-full h-full max-h-[308px] transition-opacity duration-[250] ${index !== current ? 'opacity-0' : 'opacity-1'}`}
                  style={{ zIndex: data.images.length - index }}
                >
                  <ResponsiveImage
                    image={image}
                    widths={{ xs: 308, sm: 308, md: 308, lg: 400, xl: 400, xxl: 400 }}
                    heights={{ xs: 308, sm: 308, md: 308, lg: 400, xl: 400, xxl: 400 }}
                    showImageCredit
                  />
                </div>
              ))}
            </div>
          )}

          {data.primaryCTA && (
            <motion.div
              variants={{ inactive: { opacity: 0 }, active: { opacity: 1, transition: { ...transition, delay: 1 } } }}
              className="lg:mt-[180px] mb-20 lg:mb-[166px]"
            >
              <Button link={data.primaryCTA} type="primary" theme={themeFromBg(isColorDark(data.backgroundColour))} />
            </motion.div>
          )}
        </div>
      </motion.div>
    </ModuleBase>
  );
};

export default SocialMediaCarousel;
