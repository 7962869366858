import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isColorDark, fixColor } from '@/utils';

const ModuleBase = ({ data, className, hTag, ...props }) => {
  let classes = ['module', className];

  const backgroundColor = fixColor(data?.backgroundColour);

  if (data?.paddingTop) {
    if (data.paddingTop.toLowerCase() === 'medium') {
      classes.push('pt-10 md:pt-12 lg:pt-16');
    } else if (data.paddingTop.toLowerCase() === 'large') {
      classes.push('pt-16 md:pt-20 lg:pt-26');
    } else if (data.paddingTop.toLowerCase() !== 'none') {
      classes.push('pt-6 md:pt-8 lg:pt-10');
    }
  }

  if (data?.paddingBottom) {
    if (data.paddingBottom.toLowerCase() === 'medium') {
      classes.push('pb-10 md:pb-12 lg:pb-16');
    } else if (data.paddingBottom.toLowerCase() === 'large') {
      classes.push('pb-16 md:pb-20 lg:pb-26');
    } else if (data.paddingBottom.toLowerCase() !== 'none') {
      classes.push('pb-6 md:pb-8 lg:pb-10');
    }
  }

  if (isColorDark(backgroundColor)) {
    classes.push('[&_.color-from-bg]:text-secondary-coolsand [&_.color-from-bg>path]:!fill-white');
  } else {
    classes.push('[&_.color-from-bg]:text-primary [&_.color-from-bg>path]:!fill-primary');
  }

  classes.push(data?.moduleName || null);

  return React.createElement(hTag?.toLowerCase() || 'section', {
    id: data?.anchor || null,
    style: {
      backgroundColor,
    },
    className: classnames(classes),
    ...props,
  });
};

ModuleBase.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ModuleBase;
