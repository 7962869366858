import React, { useState, useRef, useEffect } from 'react';
import { FormControl, PopoverMenu } from '@/components';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useRouter } from 'next/router';

const AvailabilityForm = ({ availabilityData, searchClass, placement, selectedData, setSelectedData }) => {
  const [hotel, setHotel] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);

  const router = useRouter();
  const { t } = useTranslation('common');

  const datepicker = useRef();
  const guests = useRef();

  const _setHotel = (event) => {
    setHotel(event.target.value);
    setSelectedData({ ...selectedData, hotel: event.target.value });
    datepicker.current.open();
  };
  const setDates = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setSelectedData({ ...selectedData, startDate: newStartDate, endDate: newEndDate });
    if (newStartDate && newEndDate) {
      guests.current.open();
    }
  };
  const _setAdults = (event) => {
    setAdults(event.target.value);
    setSelectedData({ ...selectedData, adults: event.target.value });
  };
  const _setChildren = (event) => {
    setChildren(event.target.value);
    setSelectedData({ ...selectedData, children: event.target.value });
  };

  useEffect(() => {
    if (selectedData.hotel !== hotel) {
      setHotel(selectedData.hotel);
    }
    if (selectedData.startDate !== startDate) {
      setStartDate(selectedData.startDate);
    }
    if (selectedData.endDate !== endDate) {
      setEndDate(selectedData.endDate);
    }
    if (selectedData.adults !== adults) {
      setAdults(selectedData.adults);
    }
    if (selectedData.children !== children) {
      setChildren(selectedData.children);
    }
  }, [selectedData, hotel, startDate, endDate, adults, children]);

  const formValid = !!hotel && !!startDate && !!endDate;

  let bookingUrl = {};
  if (availabilityData?.bookingSystemUrl) {
    try {
      const slash = availabilityData.bookingSystemUrl.endsWith('/') ? '' : '/';

      bookingUrl = new URL(availabilityData.bookingSystemUrl + slash);
      bookingUrl.searchParams.set('locale', router.locale);

      if (hotel) {
        bookingUrl.searchParams.set('hotel', hotel);
      }
      if (startDate) {
        bookingUrl.searchParams.set('arrive', dayjs(startDate)?.format('YYYY-MM-DD'));
      }

      if (endDate) {
        bookingUrl.searchParams.set('depart', dayjs(endDate)?.format('YYYY-MM-DD'));
      }

      if (adults) {
        bookingUrl.searchParams.set('Adult', adults);
      }

      if (children) {
        bookingUrl.searchParams.set('child', children);
      }
    } catch (e) {
      console.error('Invalid URL: ' + availabilityData.bookingSystemUrl);
    }
  }

  return (
    <>
      <div className="form-items-container flex flex-col lg:flex-row gap-1 [&_.main-button]:!h-12 [&_.input-group_.dropdown.open_.content]:!drop-shadow-[0_0_0_rgba(0,0,0,0)]">
        <div className="w-full form-item hotel">
          <FormControl
            type="select"
            className={classNames(
              `dropdown [&_.main-button]:pt-[11px] [&_.main-button]:pb-[11px] [&_em]:not-italic 
              h-auto lg:h-[56px] [&_.main-button]:!h-[56px]
              [&_.content_li]:bg-[#eae6e2] [&_.content_li>div]:text-primary [&_.content_li>div]:text-base [&_.content_ul]:bg-transparent [&_.content_ul]:rounded-none
              [&_.content_li>div]:flex [&_.content_li>div]:items-center [&_.content_li>div]:h-[48px] [&_.content_li>div]:px-6 [&_.content_li>div]:py-3
              [&_.content]:!h-[288px] [&_.content]:rounded-none 
              [&_.content]:overflow-y-auto 
              [&_.popover-title]:leading-16 [&_.popover-title]:relative [&_.popover-title]:top-0 [&_.popover-title]:-left-1 
              [&.open_.popover-title]:text-paragraph-normal [&.open_.popover-title]:opacity-100 [&.open_.popover-title]:top-0 [&.open_.popover-title]:-left-1
              [&.open_.popover-title]:tracking-[0]
              `,
              hotel && 'selected',
            )}
            value={hotel}
            onChange={_setHotel}
            displayEmpty={false}
            placeholder={t('booking.$selectHotel')}
            options={availabilityData?.hotels.map((item) => ({
              value: item.bookingCode,
              label: item.hotelName,
            }))}
            placement={placement}
            mobileRelative={true}
          />
        </div>
        <div className="spacer" />
        <div className="w-full form-item dates">
          <FormControl
            popoverRef={datepicker}
            type="datepicker"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(event) => setDates(event.target.value)}
            placeholder={t('booking.$checkInCheckOut')}
            minDate={new Date(availabilityData?.nextBookableDate)}
            className={classNames(
              `h-auto lg:h-[56px] [&_.content]:!h-[364px] [&_.main-button]:!h-[56px]
              [&_.popover-title]:leading-16 [&_.popover-title]:relative [&_.popover-title]:top-0 [&_.popover-title]:-left-1 
              [&.open_.popover-title]:text-paragraph-normal [&.open_.popover-title]:opacity-100 [&.open_.popover-title]:top-0 [&.open_.popover-title]:-left-1
              [&.open_.popover-title]:tracking-[0]`,
              startDate && endDate && 'selected',
            )}
            placement={placement}
            mobileRelative={true}
          />
        </div>
        <div className="spacer" />
        <div className="w-full form-item guests input-group mt-0">
          <PopoverMenu
            title={t('booking.$xAdultsXChildren', { adults, children })}
            className={classNames(
              `h-auto lg:h-[56px] [&_.content]:!h-[167px] [&_.main-button]:!h-[56px] dropdown
              [&_.popover-title]:leading-16 [&_.popover-title]:relative [&_.popover-title]:top-0 [&_.popover-title]:-left-1 
              [&.open_.popover-title]:text-paragraph-normal [&.open_.popover-title]:opacity-100 [&.open_.popover-title]:top-0 [&.open_.popover-title]:-left-1
              [&.open_.popover-title]:tracking-[0]
              `,
              (adults !== 2 || children !== 0) && 'selected',
            )}
            placement={placement}
            ref={guests}
            mobileRelative={true}
          >
            <div className="p-3 w-full">
              <div className="flex items-center justify-between [&_.input-group]:!mt-0">
                <div className="font-montserrat text-base not-italic font-normal leading-6 py-2.5">
                  {t('booking.$adults')}
                </div>
                <FormControl
                  type="number"
                  value={adults}
                  onChange={(event) => _setAdults(event)}
                  minValue={1}
                  maxValue={availabilityData?.maxAdults}
                  className="input !p-0 !w-24 [&_input]:mx-3"
                />
              </div>
              <div className="flex items-center justify-between [&_.input-group]:!mt-0">
                <div className="py-2.5">
                  <div className="font-montserrat text-base not-italic font-normal leading-6">
                    {t('booking.$children')}
                    <div className="text-[12px] font-thin h-0">{t('booking.$agedUnder')}</div>
                  </div>
                </div>
                <FormControl
                  type="number"
                  value={children}
                  onChange={(event) => _setChildren(event)}
                  minValue={0}
                  maxValue={availabilityData?.maxChildren}
                  className="input !p-0 !w-24 [&_input]:mx-3"
                />
              </div>
              <div className="apply" onClick={() => guests.current.close()}>
                {' '}
                {t('booking.$xAdultsXChildrenApply')}
              </div>
            </div>
          </PopoverMenu>
        </div>
      </div>

      <div className="search-container flex [&>a]:!text-primary  ">
        <a
          href={bookingUrl.href}
          className={classNames(
            'btn px-12 w-[353px] lg:w-[210px] xxl:w-[260px] !h-[56px] !bg-highlight-sunshineYellow hover:!bg-highlight-sunshineYellow/75',
            searchClass,
            !formValid && '!opacity-25 pointer-events-none',
          )}
          aria-label={t('general.$opensanewwindow', { ariaLabel: t('booking.$checkAvailability') })}
          target="_blank"
        >
          {t('booking.$checkAvailability')}
        </a>
      </div>
    </>
  );
};

export default AvailabilityForm;
