/* eslint-disable @next/next/no-img-element */
import MenuIcon from '@/assets/menuIcon.svg';
import { Button, Link, PopoverMenu, ResponsiveImage } from '@/components';
import Nav from '@/components/nav/Nav';
import { selectHideNavigation } from '@/store';
import { selectNoHeroModuleBgColor } from '@/store/index';
import tailwindConfig from '@/tailwind.config';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { isChrome, isIOS, isSafari } from 'react-device-detect';
import { useSelector } from 'react-redux';

const Header = ({ header, metadata }) => {
  const { t } = useTranslation('common');
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouter();
  const [bgOnTop, setBgOnTop] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [ctaText, setCtaText] = useState('');
  const [clientOnlyData, setClientOnlyData] = useState(null);
  const hideNav = useSelector(selectHideNavigation);
  const noHeroModule = useSelector(selectNoHeroModuleBgColor);
  const noHeroModuleBgColorLight = !isColorDark(useSelector(selectNoHeroModuleBgColor));
  const needDarkVersion = !!noHeroModule && !!noHeroModuleBgColorLight;
  const hasSecondaryLogo = header?.logoElement?.secondaryLogo;
  const [open, setOpen] = useState(false);
  const { additionalBookingLinks } = header.booking;
  const bookingScript = additionalBookingLinks.find((item) => item.bookingScript !== null)?.bookingScript;

  const addQueryParam = (params) => {
    router.replace(
      {
        query: { ...router.query, ...params },
      },
      undefined,
      { shallow: true },
    );
  };

  useEffect(() => {
    document.querySelector('#bookingWidgetScript')?.remove();
    const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/g;
    let match;
    let bookingWidgetInnerScript;
    let bookingWidgetScript;

    const loadWidget = () => {
      while ((match = scriptRegex.exec(bookingScript)) !== null) {
        bookingWidgetInnerScript = document.createElement('script');
        bookingWidgetInnerScript.async = true;
        bookingWidgetInnerScript.defer = true;
        bookingWidgetInnerScript.id = 'bookingWidgetInnerScript';
        if (!document.querySelector('#bookingWidgetInnerScript') && !match[0].includes('src')) {
          const scriptContent = match[1].trim();
          bookingWidgetInnerScript.innerHTML = scriptContent;
          document.body.appendChild(bookingWidgetInnerScript);
        }
      }
    };
    if (bookingScript) {
      while ((match = scriptRegex.exec(bookingScript)) !== null) {
        bookingWidgetScript = document.createElement('script');
        bookingWidgetScript.async = true;
        bookingWidgetScript.defer = true;
        bookingWidgetScript.id = 'bookingWidgetScript';
        if (!document.querySelector('#bookingWidgetScript') && match[0].includes('src')) {
          bookingWidgetScript.src = match[0].match(/src="([^"]+)"/)[1];
          bookingWidgetScript.onload = function () {
            loadWidget();
          };
          document.body.appendChild(bookingWidgetScript);
        }
      }
    }

    setClientOnlyData(true);

    return () => {
      if (typeof bookingWidgetInnerScript !== 'undefined' && bookingWidgetInnerScript.parentNode === document.body) {
        document.body.removeChild(bookingWidgetInnerScript);
      }
      if (typeof bookingWidgetScript !== 'undefined' && bookingWidgetScript.parentNode === document.body) {
        document.body.removeChild(bookingWidgetScript);
      }
    };
  }, [bookingScript]);

  const openWidget = () => {
    if (bookingScript) {
      const regex = /id="([^"]+)"/;
      const match = regex.exec(bookingScript);
      const id = match ? match[1] : null;
      const element = document.getElementById(id);
      if (element) {
        element.click();
      }
    }
  };

  useEffect(() => {
    const onScroll = () => {
      setBgOnTop(window.scrollY === 0);
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > prevScrollPos) {
        setShowMenu(false);
        if (open) {
          setOpen(false);
        }
      } else if (currentScrollPos < prevScrollPos - 10 || window.scrollY === 0) {
        setShowMenu(true);
      }

      if ((isIOS && isSafari) || (isIOS && isChrome)) {
        if (currentScrollPos === 0 && prevScrollPos < 0) {
          setShowMenu(true);
        }
      }

      setPrevScrollPos(currentScrollPos);
    };
    const handleResize = () => {
      setCtaText(
        window.innerWidth >= parseInt(tailwindConfig.theme.screens.sm, 10)
          ? t('general.$menuBookHotelLong')
          : t('general.$menuBookHotelShort'),
      );
    };

    handleResize();

    // clean up code
    window.addEventListener('resize', handleResize);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [prevScrollPos, t, open]);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenus = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [router]);

  return (
    <div
      className={classNames(
        'fixed top-0 z-20 w-full transition-all duration-1000 ',
        bgOnTop ? 'bg-transparent on-top' : 'bg-secondary-warmsand',
        showMenu ? '' : '-translate-y-full',
      )}
    >
      <div
        className={classNames(
          'container relative flex justify-between items-center py-2 md:py-6 font-medium font-cera px-4 md:px-10 h-[76px] md:h-[104px] short:h-[76px] short:py-2 short:md:py-6 transition-opacity duration-[1.5s]',
          hideNav ? 'opacity-0 pointer-events-none' : 'opacity-100',
        )}
      >
        <div className="w-[110px] md:w-1/3 flex">
          <button
            onClick={openMenu}
            className={classNames(
              'text-sm not-italic font-medium leading-5 tracking-button-reg uppercase font-montserrat   ',
              'cursor-pointer duration-200 flex items-center py-3',
            )}
            aria-label={t('general.$menu')}
          >
            <div
              className="hidden sm:block relative w-4 h-[11px] mr-[13px]"
              aria-label="hamburger menu icon"
              role="presentation"
            >
              <MenuIcon
                className={classNames(
                  'absolute top-0 left-0 hidden sm:block sm:mr-2 transition-all duration-1000 ',
                  bgOnTop && !needDarkVersion ? 'opacity-0 ' : 'opacity-100',
                )}
                aria-label="hamburger menu"
                role="presentation"
              />
              <MenuIcon
                className={classNames(
                  'absolute top-0 left-0 hidden sm:block sm:mr-2 transition-all duration-1000 [&_path]:fill-white ',
                  bgOnTop && !needDarkVersion ? 'opacity-100 ' : 'opacity-0',
                )}
              />
            </div>
            <span
              className={classNames(
                'transition-color duration-1000 normal-case',
                bgOnTop && !needDarkVersion ? 'text-white' : 'text-primary',
              )}
            >
              {t('general.$menu')}
            </span>
          </button>
        </div>

        <div className="w-fit grow  flex justify-center absolute left-1/2 -translate-x-1/2">
          {header?.logoElement && header?.logoElement.logo && (
            <div className="h-auto max-w-[146px] md:max-w-[185px] max-h-[76px] md:max-h-[104px] short:max-h-[76px] pt-[3px]">
              <Link
                link={header.logoElement.homeLink ?? { href: '/' }}
                databefore={header.logoElement.homeLink.text ?? header.logoElement.logo.imageAlt}
                aria-label={header.logoElement.homeLink.text ?? header.logoElement.logo.imageAlt}
              >
                {hasSecondaryLogo ? (
                  bgOnTop && !needDarkVersion ? (
                    <ResponsiveImage
                      image={header.logoElement.logo}
                      heights={{ xs: 200 }}
                      dataNotLazy
                      imgProps={{
                        className: 'w-full h-full block object-contain transition-all duration-1000 short:scale-[0.85]',
                      }}
                    />
                  ) : (
                    <ResponsiveImage
                      image={header.logoElement.secondaryLogo}
                      heights={{ xs: 200 }}
                      dataNotLazy
                      imgProps={{
                        className: 'transition-all duration-1000 w-full h-full block object-contain short:scale-[0.85]',
                      }}
                    />
                  )
                ) : (
                  <ResponsiveImage
                    image={header.logoElement.logo}
                    heights={{ xs: 200 }}
                    dataNotLazy
                    imgProps={{
                      className:
                        bgOnTop && !needDarkVersion
                          ? 'w-full h-full block object-contain transition-all duration-1000 short:scale-[0.85]'
                          : 'transition-all duration-1000 invert w-full h-full block object-contain short:scale-[0.85]',
                    }}
                  />
                )}
              </Link>
            </div>
          )}
        </div>
        <div className="h-full flex justify-end items-center">
          {header.booking.hotelBookingLink && (additionalBookingLinks?.length === 0 || !additionalBookingLinks) ? (
            <Button
              className="ml-auto [&_.btn]:transition [&_.btn]:duration-1000"
              theme={bgOnTop && !needDarkVersion ? 'light' : 'dark'}
              link={{
                ...header.booking.hotelBookingLink,
                text: ctaText,
              }}
              type={'primary'}
            ></Button>
          ) : (
            <PopoverMenu
              open={open}
              onOpen={() => setOpen(true)}
              title="Book"
              contentPlacement="bottom-end"
              className={`w-full flex flex-col items-end justify-center [&_.content]:flex [&_.content]:flex-col 
                [&_.content]:rounded-[4px]  [&_.main-button]:transition-all [&_.main-button]:duration-2000
                [&_.main-button]:text-paragraph-normal [&_.main-button]:w-fit [&_.arrow]:ml-3
                [&_.content-container]:h-0 [&_.content-container-open]:!h-full [&_.main-button]:lg:w-[160px] 
                ${
                  bgOnTop
                    ? '[&_svg]:fill-white/75 [&_.main-button]:border [&_.main-button]:border-solid [&_.main-button]:border-[white] transition-[border] duration-2000 text-white'
                    : '[&_svg]:fill-black/75 [&_.main-button]:border [&_.main-button]:border-solid [&_.main-button]:border-[black] [&_.main-button]:transition-[border] [&_.main-button]:duration-2000 text-black'
                }
                ${open ? '[&_.content-container]:w-[calc(100vw-32px)] md:[&_.content-container]:w-[calc(100vw-80px)] lg:[&_.content-container]:w-[160px]' : ''}
                `}
            >
              <div className="bg-secondary-coolsand p-2">
                <div className=" bg-[#eae6e2] p-2">
                  <Link
                    link={{
                      ...header.booking.hotelBookingLink,
                      text: t('general.$menuBookHotelLong'),
                    }}
                    className={`${open ? 'opacity-100' : 'opacity-0 cursor-default'} mb-4 text-primary !block  text-paragraph-normal not-italic font-normal leading-24 tracking-[0.16px] relative z-10  `}
                  ></Link>

                  {additionalBookingLinks?.map(({ bookingLabel, bookingScript, bookingLink }, index) => {
                    if (bookingScript?.length > 0 && !bookingLink) {
                      return (
                        clientOnlyData && (
                          <div
                            key={index}
                            className="block w-full text-primary mb-4 cursor-pointer last:mb-0 text-paragraph-normal not-italic font-normal leading-24 tracking-[0.16px]"
                            onClick={openWidget}
                          >
                            {bookingLabel || ctaText}
                            {bookingScript && (
                              <div className="hidden" dangerouslySetInnerHTML={{ __html: bookingScript }} />
                            )}
                          </div>
                        )
                      );
                    }

                    return (
                      bookingLink && (
                        <Link
                          key={index}
                          link={{ href: bookingLink }}
                          className="block w-full text-primary mb-4 cursor-pointer last:mb-0 text-paragraph-normal not-italic font-normal leading-24 tracking-[0.16px]"
                        >
                          {bookingLabel}
                        </Link>
                      )
                    );
                  })}
                </div>
              </div>
            </PopoverMenu>
          )}
        </div>
      </div>
      <Nav
        open={menuOpen}
        onClose={closeMenus}
        navItems={header?.mainNavItems}
        mainNavTitle={header?.mainNavTitle}
        globalNavItems={header?.globalNavItems}
        allHotels={header?.allHotels}
        addQueryParam={addQueryParam}
        metadata={metadata}
        availabilityBar={!!header.availabilityBar}
        logoElement={header.logoElement}
      />
    </div>
  );
};

export default Header;
