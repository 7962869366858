import AccordionModule from './AccordionModule.jsx';
import AnchorBarModule from './AnchorBarModule.jsx';
import BannerAndMediaModule from './BannerAndMediaModule.jsx';
import BannerFullBleedModule from './BannerFullBleedModule.jsx';
import BannerSlimModule from './BannerSlimModule.jsx';
import BreadcrumbsModel from './BreadcrumbsModel.jsx';
import CTabreakModule from './CTabreakModule.jsx';
import CTabreakWithLogoModule from './CTabreakWithLogoModule.jsx';
import CardCarouselFocusModule from './CardCarouselFocusModule.jsx';
import CardCarouselGenericModule from './CardCarouselGenericModule.jsx';
import CardCarouselOutOfScreenModule from './CardCarouselOutOfScreenModule.jsx';
import CardListItemsModule from './CardListItemsModule.jsx';
import CardListingGridModule from './CardListingGridModule.jsx';
import ContactsModule from './ContactsModule.jsx';
import EmbedModule from './EmbedModule.jsx';
import FooterHorizontal from './FooterHorizontal.jsx';
import FooterVertical from './FooterVertical.jsx';
import FormModule from './FormModule.jsx';
import Header from './Header.jsx';
import HeadingSideModule from './HeadingSideModule.jsx';
import HeadingVerticalModule from './HeadingVerticalModule.jsx';
import HeroArticleModule from './HeroArticleModule.jsx';
import HeroCarouselCentreAlignedModule from './HeroCarouselCentreAlignedModule.jsx';
import HeroCarouselLeftAlignedModule from './HeroCarouselLeftAlignedModule.jsx';
import HeroCenterAlignedModule from './HeroCenterAlignedModule.jsx';
import HeroLeftAlignedModule from './HeroLeftAlignedModule.jsx';
import HeroRightAlignedModule from './HeroRightAlignedModule.jsx';
import HeroSlimCenterAlignedModule from './HeroSlimCenterAlignedModule.jsx';
import HeroSlimLeftAlignedModule from './HeroSlimLeftAlignedModule.jsx';
import HeroWithTwoMediaModule from './HeroWithTwoMediaModule.jsx';
import HomeHeroModule from './HomeHeroModule.jsx';
import IconListModule from './IconListModule.jsx';
import IconListWithMediaModule from './IconListWithMediaModule.jsx';
import IconListWithRichTextModule from './IconListWithRichTextModule/IconListWithRichTextModule.jsx';
import ListAndMediaModule from './ListAndMediaModule.jsx';
import ListRichTextModule from './ListRichTextModule.jsx';
import ListTwoMediaModule from './ListTwoMediaModule.jsx';
import LogoListModule from './LogoListModule.jsx';
import MapFullBleedModule from './MapFullBleedModule/MapFullBleedModule.jsx';
import MediaCarouselFullBleedModule from './MediaCarouselFullBleedModule.jsx';
import MediaCarouselModule from './MediaCarouselModule.jsx';
import MediaColumnsLandscapeModule from './MediaColumnsLandscapeModule.jsx';
import MediaColumnsPortraitModule from './MediaColumnsPortraitModule.jsx';
import MediaMasonryGridModule from './MediaMasonryGridModule.jsx';
import MenuModule from './MenuModule/MenuModule.jsx';
import NoHeroModule from './NoHeroModule.jsx';
import QuoteCarouselModule from './QuoteCarouselModule.jsx';
import QuoteModule from './QuoteModule.jsx';
import QuoteWithMediaModule from './QuoteWithMediaModule.jsx';
import RichTextAndMediaOnFullBleedModule from './RichTextAndMediaOnFullBleedModule.jsx';
import RichTextAndOneMediaModule from './RichTextAndOneMediaModule.jsx';
import RichTextAndTwoMediaModule from './RichTextAndTwoMediaModule.jsx';
import RichTextAndTwoPortraitStackedModule from './RichTextAndTwoPortraitStackedModule.jsx';
import RichTextColumnsModule from './RichTextColumnsModule.jsx';
import RichTextModule from './RichTextModule.jsx';
import RichTextWithMediaModule from './RichTextWithMediaModule.jsx';
import TabsModule from './TabsModule.jsx';
import SmoothScroller from './SmoothScroller.jsx';
import SocialMediaCarousel from './SocialMediaCarousel.jsx';
import StickyBookingBarModule from './StickyBookingBarModule.jsx';
import BookingBarModule from './BookingBarModule.jsx';

const Modules = {
  AccordionModule,
  AnchorBarModule,
  BannerAndMediaModule,
  BannerFullBleedModule,
  BannerSlimModule,
  BreadcrumbsModel,
  CTabreakModule,
  CTabreakWithLogoModule,
  CardCarouselFocusModule,
  CardCarouselGenericModule,
  CardCarouselOutOfScreenModule,
  CardListItemsModule,
  CardListingGridModule,
  ContactsModule,
  EmbedModule,
  FooterHorizontal,
  FooterVertical,
  FormModule,
  Header,
  HeadingSideModule,
  HeadingVerticalModule,
  HeroArticleModule,
  HeroCarouselCentreAlignedModule,
  HeroCarouselLeftAlignedModule,
  HeroCenterAlignedModule,
  HeroLeftAlignedModule,
  HeroRightAlignedModule,
  HeroSlimCenterAlignedModule,
  HeroSlimLeftAlignedModule,
  HeroWithTwoMediaModule,
  HomeHeroModule,
  IconListModule,
  IconListWithMediaModule,
  IconListWithRichTextModule,
  ListAndMediaModule,
  ListRichTextModule,
  ListTwoMediaModule,
  LogoListModule,
  MapFullBleedModule,
  MediaCarouselFullBleedModule,
  MediaCarouselModule,
  MediaColumnsLandscapeModule,
  MediaColumnsPortraitModule,
  MediaMasonryGridModule,
  MenuModule,
  NoHeroModule,
  QuoteCarouselModule,
  QuoteModule,
  QuoteWithMediaModule,
  RichTextAndMediaOnFullBleedModule,
  RichTextAndOneMediaModule,
  RichTextAndTwoMediaModule,
  RichTextAndTwoPortraitStackedModule,
  RichTextColumnsModule,
  RichTextModule,
  RichTextWithMediaModule,
  TabsModule,
  SmoothScroller,
  SocialMediaCarousel,
  StickyBookingBarModule,
  BookingBarModule,
};

export default Modules;
