import React, { useState, useEffect } from 'react';
import { pushValuesToRouteQuery, assignValuesFromRouteQuery, isColorDark } from '@/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { motion } from 'framer-motion';
import Close from '@/assets/close.svg';
import cn from 'classnames';
import { ModalPortal } from '..';
import PlusIcon from '@/assets/plusIcon.svg';

const OffersAndEventsFiltering = ({
  data,
  queryMode,
  backgroundColour,
  slicedCardsLength,
  totalCardsLength,
  ...props
}) => {
  const isDark = isColorDark(backgroundColour);

  const [currentPage, setCurrentPage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const router = useRouter();
  const { t } = useTranslation('common');
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    const queryObj = { type: '', category: '' };
    assignValuesFromRouteQuery(router, queryObj);

    if (!queryObj.type && data?.types?.length > 0 && data.showFilters) {
      const firstType = data.types[0];
      setCurrentPage(firstType);
      setPage(firstType, queryObj.category);
    } else {
      setCurrentPage(queryObj.type);
      setSelectedCategory(queryObj.category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, data?.types]);

  const setPage = (newPage, category = selectedCategory) => {
    if (queryMode) {
      const updatedCategory = newPage === 'Event' ? '' : category;
      pushValuesToRouteQuery(router, {
        type: newPage !== '' ? newPage : null,
        category: updatedCategory !== '' ? updatedCategory : null,
      });
    }
  };

  const setCategory = (newCategory) => {
    const updatedCategory = newCategory === selectedCategory ? '' : newCategory;
    setSelectedCategory(updatedCategory);
    setPage(currentPage, updatedCategory);
  };

  return (
    <>
      {data.showFilters && (
        <div className="flex justify-center items-center" {...props}>
          <div className="tabs  sm:h-[137px]">
            <div className="mb-12">
              {data?.types?.map((type, index) => (
                <button
                  key={index}
                  onClick={() => setPage(type)}
                  className={cn(
                    currentPage === type || (!currentPage && index === 0)
                      ? 'opacity-100 !font-normal'
                      : 'opacity-50 !font-[300]',
                    isDark ? 'text-white' : 'text-primary',
                    'relative w-[91px] h-12 sm:w-[127px] sm:py-4 sm:px-8 text-primary text-title-s leading-8 font-ivy-ora -tracking-tight',
                  )}
                >
                  {currentPage === type || (!currentPage && index === 0) ? (
                    <motion.div
                      className={cn('underline-tab -mt-10', isDark ? 'bg-white' : 'bg-primary')}
                      layoutId="underline-tab"
                    />
                  ) : null}
                  {type}
                </button>
              ))}
            </div>

            <div className="desktop-filtering hidden sm:flex">
              {(currentPage === 'Offers' || !currentPage) && (
                <div className="filters gap-4 sm:flex  sm:items-center sm:justify-center ">
                  {data?.types && (
                    <div
                      className={cn(
                        'hidden sm:block font-cera text-tag-m not-italic font-normal leading-24',
                        isDark ? 'text-white' : 'text-primary',
                      )}
                    >
                      <span className="mb-1 inline-block">{t('map.$filterby')}</span>
                    </div>
                  )}

                  {data?.categories?.map((category, index) => (
                    <div
                      key={index}
                      onClick={() => setCategory(category)}
                      className={cn(
                        'relative hidden sm:flex border rounded-full h-[41px] cursor-pointer transition cursor-pointer justify-center items-center font-cera text-tag-m not-italic font-normal leading-24 px-4 border rounded-full cursor-pointer transition w-fit',
                        isDark ? 'border-secondary-coolsand' : 'border-primary',
                        selectedCategory === category
                          ? isDark
                            ? 'text-primary bg-secondary-coolsand'
                            : 'text-white bg-primary'
                          : isDark
                            ? 'text-secondary-coolsand'
                            : 'text-primary',
                      )}
                    >
                      <span className="mb-1">{category}</span>
                    </div>
                  ))}

                  {data?.types && (
                    <div
                      onClick={() => setCategory(null)}
                      className={cn(
                        isDark ? 'text-white' : 'text-primary',
                        ' cursor-pointer hidden sm:block  relative font-cera text-tag-m not-italic font-normal leading-24',
                      )}
                    >
                      <span className="inline-block mb-1 underline">{t('general.$clearall')}</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="mobile-filter-open flex justify-between w-full sm:hidden">
              {(currentPage === 'Offers' || !currentPage) && (
                <div
                  className="flex items-center mr-auto"
                  aria-label={t('general.$filters')}
                  onClick={() => setFilterOpen(true)}
                >
                  <div
                    className={cn(
                      'font-cera text-tag-m not-italic font-normal leading-24 mr-2',
                      isDark ? 'text-white' : 'text-primary',
                    )}
                  >
                    {t('general.$filters')}
                  </div>
                  <PlusIcon className={cn(isDark ? 'fill-secondary-coolsand' : 'fill-primary')} />
                </div>
              )}

              <div className="showing-wrapper ml-auto block sm:hidden">
                <div
                  className={cn(
                    'font-cera text-tag-m not-italic font-normal leading-24',
                    isDark ? 'text-white' : 'text-primary',
                  )}
                >
                  {t('map.$showingXofX', { showing: slicedCardsLength, total: totalCardsLength })}
                </div>
              </div>
            </div>
          </div>

          <ModalPortal aria-modal="true" role="dialog">
            <div
              className={cn('fixed flex flex-col justify-between top-0 left-0 z-40 w-screen h-dvh duration-500 px-4', {
                '-translate-x-full': !filterOpen,
              })}
              style={{ backgroundColor: backgroundColour }}
            >
              <div>
                <div className="flex justify-center mx-auto mb-12 mt-[25%]">
                  <div
                    className={cn(
                      isDark ? 'text-secondary-coolsand' : 'text-primary',
                      'color-from-bg text-tag-m not-italic font-normal leading-24',
                    )}
                  >
                    {t('general.$filters')}
                  </div>
                  <div className="-mr-2 -mt-2 p-2" onClick={() => setFilterOpen(false)}>
                    <Close className={cn(isDark ? 'fill-secondary-coolsand' : 'fill-primary')} />
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  {data?.categories?.map((category) => (
                    <div
                      key={category}
                      onClick={() => setCategory(category)}
                      className={cn(
                        'relative flex border rounded-full h-[41px] transition cursor-pointer justify-center items-center font-cera text-tag-m not-italic font-normal leading-24 px-4 bordertransition w-fit',
                        isDark ? 'border-secondary-coolsand' : 'border-primary',
                        selectedCategory === category
                          ? isDark
                            ? 'text-primary bg-secondary-coolsand'
                            : 'text-white bg-primary'
                          : isDark
                            ? 'text-secondary-coolsand'
                            : 'text-primary',
                      )}
                    >
                      <span>{category}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-center gap-2 w-full mb-[25%]">
                <button
                  className={`btn primary  ${isDark ? 'light' : 'dark'}`}
                  onClick={() => {
                    setCategory(null);
                    setFilterOpen(false);
                  }}
                >
                  <span className={`${isDark ? 'text-secondary-coolsand' : 'text-primary'}`}>
                    {t('general.$clearall')}
                  </span>
                </button>

                <button
                  className={`btn secondary  ${data.backgroundColour === '#081B36' ? '!bg-secondary-coolsand !text-primary' : '!bg-highlight-sunshineYellow'}`}
                  onClick={() => setFilterOpen(false)}
                >
                  <span className={`text-primary`}>{t('general.$apply')}</span>
                </button>
              </div>
            </div>
          </ModalPortal>
        </div>
      )}
    </>
  );
};
export default OffersAndEventsFiltering;
